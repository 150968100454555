<template>
	<div class="User">
		
		<div class="Top">
			<div class="Left">
				帮助分类详情
			</div>
			<div class="Right">
				<span>
					<el-button type="primary" icon="el-icon-arrow-left" @click="$router.go(-1)">返回帮助分类列表</el-button>
				</span>
			</div>
		</div>
		
		<div class="List">
			
			<div class="One">
				<li v-if="Category.Id != 'add'">
					<span>分类ID</span>
					<em>
						{{Category.Id}}
					</em>
				</li>
				
				
				<li>
					<span>分类名称</span>
					<em>
						<el-input v-model="Category.Name" style="width: 200px;"></el-input>
					</em>
				</li>
				<li>
					<span>分类排序权</span>
					<em>
						<el-input v-model="Category.Order" style="width: 100px;"></el-input>
						<i>填入数字，越大排位越靠前</i>
					</em>
				</li>
				
				<li v-if="Category.Id != 'add'">
					<span>分类状态</span>
					<em>
						<el-select placeholder="设置分类状态" v-model="Category.Status">
							<el-option label="删除" :value="0" disabled></el-option>
							<el-option label="启用" :value="50"></el-option>
						    <el-option label="停用" :value="10"></el-option>
					    </el-select>
					</em>
				</li>
				
				<li v-if="Category.Id != 'add'">
					<span>创建时间</span>
					<em>
						{{Category.CreatedAt}}
					</em>
				</li>
				
				<li v-if="Category.Id != 'add'">
					<span>最后修改时间</span>
					<em>
						{{Category.UpdatedAt}}
					</em>
				</li>
				
				<li>
					<span></span>
					<em>
						<el-button @click="UpdateCategory()" type="danger">
							{{Category.Id == 'add' ? '创建分类':'修改分类'}}
						</el-button>
					</em>
				</li>
			</div>
		</div>
	
		
	</div>
</template>

<script>
	import {Select,Option,Dialog} from 'element-ui'
	export default {
	  name: 'HelpCategoryInfo',
	  props: {
	  },
	  data() {
	      return {
			  Category:{
				  Id:'',
				  Name:'',
				  CreatedAt:'',
				  Status:50,
				  Order:1,
				  StatusName:'启用中',
				  UpdatedAt:''
			  },
	      }
	  },
	  components: {
		'el-select':Select,
		'el-option':Option,
		'el-dialog':Dialog
	  },
	  created() {
	  	if(this.$route.params.Id == undefined){
			this.$message('缺少分类身份ID，页面无法工作')
			return
		}
		this.Category.Id = this.$route.params.Id
		if(this.Category.Id != "add"){
			this.GetCategory(this.$route.params.Id)
		}
	  },
	  methods:{
		    GetCategory(_userId){
		  		let data = {Service:'Help',Class: 'HelpCategory',Action: 'Get',Id:this.Category.Id,}
		  		this.$post(this.$store.getters.getApiHost,data)
		  		.then((res) => {
		  			
		  			if(res.ErrorId != 0){this.$message(res.Msg);return;}
		  			this.Category = res.Data
		  			
		  		})
		    },
			UpdateCategory(){
				let data = {Service:'Help',Class: 'HelpCategory',Action: 'Update',Id:this.Category.Id,Status:this.Category.Status,Name:this.Category.Name,Order:this.Category.Order}
				if(this.Category.Id == 'add'){
					data.Action = "Add"
				}
				this.$post(this.$store.getters.getApiHost,data)
				.then((res) => {
					this.$message(res.Msg)
					if(res.ErrorId == 0){
						this.$Jump('/do/help/category_list')
					}
				})
			},
	  }
	}
</script>

<style scoped>
.User{
	background-color: #FFFFFF;
	margin: 20px;
	padding: 20px;
}
.User .Top{
	display: flex;
	align-items: center;
}
.User .Top .Left{
	
}
.User .Top .Right{
	flex: 1;
	text-align: right;
	color: #999999;
}

.User .List{
	margin-top: 20px;	
}
.User .List .One{
	border: 1px solid rgba(0,0,0,0.04);
	padding: 20px;
	margin-bottom: 20px;
}
.User .List .One li{
	display: flex;
	line-height: 20px;
	padding: 10px 0px;
	align-items: center;
}
.User .List .One li span{
	width: 140px;
	color: rgba(0,0,0,0.3);
}
.User .List .One li  em{
	flex: 1;
}
.User .List .One li i{
	font-style: normal;
	color: rgba(0,0,0,0.2);
}

.ReSetPas{
	
}
.ReSetPas li{
	display: flex;
	align-items: center;
	margin-bottom: 10px;
	padding-right: 80px;
}
.ReSetPas li span{
	width: 140px;
	text-align: right;
	padding-right: 10px;
}
</style>
